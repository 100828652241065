var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "sluzby-bg" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "sluzby-intro" }, [
            _c("h1", [
              _c("span", { staticClass: "h1-line" }, [
                _c("span", { staticClass: "hp_h1_efekt" }, [
                  _vm._v(_vm._s(_vm.headingDisplayText))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.subtitleDisplayText))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sluzby-wrapper" }, [
            _c(
              "ul",
              { staticClass: "sluzby-list" },
              _vm._l(_vm.sluzby, function(sluzba) {
                return _c(
                  "li",
                  {
                    key: sluzba.id,
                    class: { disabled: sluzba.disabled },
                    on: {
                      click: function($event) {
                        return _vm.zobrazitDetailSluzby(sluzba)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "sluzby-list-item" }, [
                      _c("span", { staticClass: "sluzby-list-title" }, [
                        _vm._v(_vm._s(sluzba.name))
                      ]),
                      _vm._v(" "),
                      sluzba.tags
                        ? _c(
                            "span",
                            { staticClass: "sluzby-list-tags" },
                            _vm._l(sluzba.tags, function(tag) {
                              return _c(
                                "span",
                                { key: tag, staticClass: "sluzby-list-tag" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(tag) +
                                      " \n                                "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "tag-pripravujeme-container" },
                        [
                          sluzba.disabled
                            ? _c("span", { staticClass: "tag-pripravujeme" }, [
                                _vm._v("Připravujeme")
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div")
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "detail-sluzby-side-shadow" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detail-sluzby", attrs: { id: "detail-sluzby-side" } },
        [
          _c(
            "div",
            { attrs: { id: "detail-sluzby-side-wrapper" } },
            [
              _c("div", { attrs: { id: "detail-sluzby-controls-container" } }, [
                _c(
                  "div",
                  {
                    staticClass: "detail-sluzby-controls",
                    attrs: { id: "detail-sluzby-controls" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "detail-sluzby-close-btn",
                        staticStyle: {
                          position: "relative",
                          width: "20px",
                          height: "20px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.skrytDetalSluzby()
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "ox-toggler__bar",
                          staticStyle: {
                            position: "absolute",
                            transform: "rotate(-45deg)",
                            top: "50%",
                            left: "0"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "ox-toggler__bar",
                          staticStyle: {
                            position: "absolute",
                            transform: "rotate(45deg)",
                            top: "50%",
                            left: "0"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("sluzba-detail", {
                attrs: { sluzba: this.detailSluzby, sluzbaSide: true }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }